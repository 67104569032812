@font-face {
  font-family: 'Ford Antenna';
  src: url('../fonts/FordAntenna/FordAntenna-Light.otf') format('opentype');
}
@font-face {
  font-family: 'FordAntenna-Medium';
  src: url('../fonts/FordAntenna/FordAntenna-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'FordAntenna-SemiBold';
  src: url('../fonts/FordAntenna/FordAntenna-Semibold.otf') format('opentype');
}

body {
  margin: 0;
  font-family: Ford Antenna, "sans-serif";
}

#root {
  height: 100vh;
}

.page {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  background-color: black;
}
.floorplan_editor_content {
  justify-content: center;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 100%;
  margin-top: 30px;
  // background-color: rgb(242, 242, 242)
}
